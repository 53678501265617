import Constants from 'constants/index';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ThumbsDown, ThumbsUp, VideoSearchResult } from 'icons';
import { Card, Tag, Typography } from 'cfa-react-components';

interface SearchResultCardImageProps {
  name: string;
  documentType: string;
  icon: string;
}

const SearchResultCardImage = ({
  name,
  icon,
  documentType,
}: SearchResultCardImageProps) =>
  documentType?.toLowerCase() ===
  Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
    <StyledVideoSearchResultIcon />
  ) : (
    <StyledDocumentImage
      alt={name}
      src={
        Constants.PATHWAY_CDN_IMG.PROCEDURES +
        (icon === 'null' || icon === 'placeholder' ? 'Chickfila' : icon) +
        '.svg'
      }
    />
  );

interface SearchResultCardProps {
  documentId: string;
  documentType: string;
  featureFlagSearchResultFeedback: boolean;
  getFeedbackText: (id: any) => any;
  icon: string;
  id: string;
  isMarketTest: boolean;
  isThumbsDown: boolean;
  isThumbsUp: boolean;
  locationTypes: string[];
  name: string;
  onClick: (id: string, resultId: string, documentId: string) => void;
  onThumbsDown: (id: string, resultId: string) => void;
  onThumbsUp: (id: string, resultId: string) => void;
  resultId: string;
}

const SearchResultCard = ({
  id,
  documentId,
  documentType,
  featureFlagSearchResultFeedback,
  getFeedbackText,
  icon,
  isMarketTest,
  isThumbsDown,
  isThumbsUp,
  locationTypes,
  name,
  onClick,
  onThumbsDown,
  onThumbsUp,
  resultId,
}: SearchResultCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledCard
      elevation={1}
      id={`a${id}`}
      key={id}
      title={name}
      variant="default"
    >
      <SearchResultCardImage
        documentType={documentType}
        icon={icon}
        name={name}
      />
      <StyledResultContent>
        <StyledCardText>
          <Link
            id="category"
            onClick={e => {
              e.preventDefault();
              onClick(id, resultId, documentId);
            }}
            to={`/doc/${documentId}`}
          >
            <SearchResultTitle variant="body1">{name}</SearchResultTitle>
          </Link>
          {isMarketTest && (
            <StyledTag label={t('Generic.marketTest')} variant="filled" />
          )}
          {!!locationTypes?.length && (
            <StyledTag
              color={
                locationTypes?.includes(Constants.RESTAURANT_CONTENT_TYPES.STC)
                  ? 'orange'
                  : 'default'
              }
              label={
                locationTypes?.includes(Constants.RESTAURANT_CONTENT_TYPES.LBM)
                  ? t('Search.restaurantContentTypes.lbm')
                  : t('Search.restaurantContentTypes.stc')
              }
              variant="filled"
            />
          )}
        </StyledCardText>
        {featureFlagSearchResultFeedback && (
          <StyledFeedbackWrapper variant="body2">
            {getFeedbackText(id)}
            <StyledThumbsWrapper variant="body2">
              <StyledThumbsUp
                onClick={() => {
                  onThumbsUp(id, resultId);
                }}
                selected={isThumbsUp}
              />
              <StyledThumbsSeparator />
              <StyledThumbsDown
                onClick={() => {
                  onThumbsDown(id, resultId);
                }}
                selected={isThumbsDown}
              />
            </StyledThumbsWrapper>
          </StyledFeedbackWrapper>
        )}
      </StyledResultContent>
    </StyledCard>
  );
};

const SearchResultTitle = styled(Typography)`
  color: ${props => props.theme.primaryPalette.navyBlue};
`;
const StyledResultContent = styled.div`
  width: 100%;
`;
const StyledCardText = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  margin-left: 10px;
`;
const StyledFeedbackWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.grayScale.gray7};
`;
const StyledThumbsWrapper = styled(Typography)`
  text-align: right;
  flex-grow: 1;
  max-width: 100%;
`;
const StyledThumbsUp = styled(ThumbsUp)`
  cursor: pointer;
  width: 35px;
  height: 35px;

  path {
    fill: ${props => (props.selected ? props.theme.tertiaryPalette.green : '')};
  }

  &:hover {
    path {
      fill: ${props => props.theme.tertiaryPalette.green};
    }
  }
`;

const StyledThumbsDown = styled(ThumbsDown)`
  cursor: pointer;
  width: 35px;
  height: 35px;

  path {
    fill: ${props => (props.selected ? props.theme.secondaryPalette.red : '')};
  }

  &:hover {
    path {
      fill: ${props => props.theme.secondaryPalette.red};
    }
  }
`;
const StyledThumbsSeparator = styled.span`
  padding: 0 10px;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.grayScale.gray2};
  flex-direction: row;
  align-items: center;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledVideoSearchResultIcon = styled(VideoSearchResult)`
  margin-right: 10px;
`;

const StyledDocumentImage = styled.img`
  width: 40px;
  display: flex;
  min-width: 40px;
  margin: 0 10px 0 0;
  border-radius: 0;
  padding: 1px;
`;

SearchResultCardImage.defaultProps = {};
SearchResultCard.defaultProps = {};

export default SearchResultCard;
