import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { useDeviceInfo } from 'util/device';
import { getNameFromLanguage } from 'util/language';
import { validateTextField } from 'util/validateTextField';
import { isEqual } from 'lodash/lang';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useGetQuizQuery,
  useAddQuizMutation,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
} from 'services/pathwayApi';
import {
  Button,
  Card,
  CardContent,
  Dropdown,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { IconEdit, IconPlus, IconWorld, IconTrash } from '@tabler/icons-react';
import StickyMenuCard from 'components/StickyMenuCard/StickyMenuCard';
import StickyMenuButton from 'components/StickyMenuCard/StickyMenuButton';
import { withRoles } from 'sharedComponents/app/withRoles';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import {
  selectUserLanguage,
  selectUserIsStaff,
  selectUserId,
} from 'store/user/selectors';
import { hideThreeDotMenu } from 'store/threeDotMenu/slice';
import { setHeader } from 'store/header/slice';
import GenericError from 'sharedComponents/app/GenericError';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import ManageTranslationsPopup from 'containers/TrainingPlans/ManageTranslationsModal/ManageTranslations';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import BuildQuizzesQuestionCard from './BuildQuizzesItems/BuildQuizzesQuestionCard';
import ValidationErrorPopup from './BuildQuizzesItems/ValidationErrorPopup';
import DeleteQuizPopup from './BuildQuizzesItems/DeleteQuizPopup';
import RenameQuizPopup from './BuildQuizzesItems/RenameQuizPopup';

const BuildQuizzesView = () => {
  const { quizId } = useParams();
  const [addQuiz] = useAddQuizMutation();
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const { t } = useTranslation();
  const userIsStaff = useSelector(selectUserIsStaff);
  const userId = useSelector(selectUserId);
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [updateQuiz] = useUpdateQuizMutation();
  const [deleteQuiz] = useDeleteQuizMutation();
  const track = useAmplitudeTrack();

  const userLanguage = useSelector(selectUserLanguage);

  const [validationMode, setValidationMode] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [quiz, setQuiz] = useState(Constants.DEFAULT_QUIZ);
  const [showSaveBeforeRenaming, setShowSaveBeforeRenaming] = useState(false);
  const [showSaveBeforeTranslating, setShowSaveBeforeTranslating] =
    useState(false);
  const [showAddToPlanPopup, setShowAddToPlanPopup] = useState(false);
  const [showConfirmClosePopUp, setShowConfirmClosePopUp] = useState(false);
  const [showDeleteQuizPopup, setShowDeleteQuizPopup] = useState(false);
  const [showRenameQuizPopup, setShowRenameQuizPopup] = useState(false);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);
  const isEditMode = !!quizId;

  const {
    data: quizData,
    isFetching: isFetchingQuiz,
    refetch: refetchGetQuizQuery,
  } = useGetQuizQuery(quizId, {
    skip: !isEditMode,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setIsEdited(
      !isEqual(
        {
          ...quiz,
          passingGrade: Number(quiz.passingGrade),
        },
        quizData,
      ),
    );
  }, [quiz, quizData]);

  useEffect(() => {
    if (
      location.state &&
      location.state.quizName &&
      (location.state.operator || userIsStaff)
    ) {
      dispatch(setHeader(location.state.quizName));
      setQuiz(prevState => ({
        ...prevState,
        operatorId: location.state.operator?.id ?? userId,
        name: { en: location.state.quizName },
      }));
    } else if (isEditMode) {
      if (quizData) {
        dispatch(setHeader(getNameFromLanguage(quizData.name)));
        setQuiz({
          ...quizData,
          passingGrade: quizData.passingGrade.toString(),
        });
      } else if (!isFetchingQuiz && !quizData) {
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/`,
        );
      }
    } else {
      history.push(
        `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/`,
      );
    }
  }, [
    dispatch,
    history,
    isFetchingQuiz,
    location,
    quizData,
    isEditMode,
    userId,
    userIsStaff,
  ]);

  const isValidQuizName = (quizName, operator) => {
    if (!quizName || !operator) {
      setShowInvalidNameError(true);
      console.error('invalid quiz name or missing operator');
      Bugsnag.notify(
        new Error(
          `Build quizzes error - no quiz name or no operator: ${JSON.stringify({
            quizName,
            operator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const addQuestion = () => {
    setValidationMode(false);
    handlePassingGrade('');
    setQuiz(prevState => ({
      ...prevState,
      questions: [
        ...prevState.questions,
        ...[Constants.DEFAULT_MULTIPLE_CHOICE_QUESTION],
      ],
    }));
  };

  const validateQuiz = () => {
    let isValid = true;
    isValid = isValid && quiz.passingGrade !== '';
    quiz.questions.forEach(question => {
      isValid =
        isValid && validateTextField(question.question?.[userLanguage] ?? '');
      isValid =
        isValid && question.answers.some(option => option.correct === true);
      question.answers.forEach(answer => {
        isValid =
          isValid && validateTextField(answer.answer?.[userLanguage] ?? '');
      });
    });
    return isValid;
  };

  const onConfirmClose = () => {
    setShowConfirmClosePopUp(false);
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`,
    });
  };

  const onSaveQuiz = () => {
    setValidationMode(true);
    const isValid = validateQuiz();
    if (!isValid) {
      setShowValidationError(true);
      return;
    } else if (isEditMode) {
      editQuiz();
    } else {
      setShowAddToPlanPopup(true);
    }
  };

  const editQuiz = () => {
    const isValid = validateQuiz();
    if (!isValid) {
      setShowValidationError(true);
      return;
    }
    // this checks if the english questions and answers are empty because the backend
    // does not accept empty english strings, and fills them with the spanish strings
    const parsedQuestions = quiz.questions.map(question => ({
      ...question,
      question: {
        ...question.question,
        en:
          question.question.en === '' && question.question.es !== ''
            ? question.question.es
            : question.question.en,
      },
      answers: question.answers.map(answer => ({
        ...answer,
        answer: {
          ...answer.answer,
          en:
            answer.answer.en === '' && answer.answer.es !== ''
              ? answer.answer.es
              : answer.answer.en,
        },
      })),
    }));

    updateQuiz({
      ...quiz,
      questions: parsedQuestions,
      passingGrade: Number(quiz.passingGrade),
    })
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(quiz.name)} ${t(
              'TrainingPlans.toastMessage.updated',
            )}`}
          </ToastMessageBlock>
        ));
        // fire amplitude event
        if (track !== null) {
          track('quiz_updated', {
            quiz_id: quizId ?? 'empty',
          });
        }
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/`,
        );
      })
      .catch(err => {
        if (isApiError(err)) {
          Constants.BUGSNAG_ENABLED &&
            Bugsnag.notify(formatBugsnagErrorMessage(err));
          return <GenericError />;
        }
      });
  };

  const createQuiz = addToPlan => {
    setShowAddToPlanPopup(false);
    // this checks if the english questions and answers are empty because the backend
    // does not accept empty english strings, and fills them with the spanish strings
    const parsedQuestions = quiz.questions.map(question => ({
      ...question,
      question: {
        ...question.question,
        en:
          question.question.en === '' && question.question.es !== ''
            ? question.question.es
            : question.question.en,
      },
      answers: question.answers.map(answer => ({
        ...answer,
        answer: {
          ...answer.answer,
          en:
            answer.answer.en === '' && answer.answer.es !== ''
              ? answer.answer.es
              : answer.answer.en,
        },
      })),
    }));
    addQuiz({
      ...quiz,
      questions: parsedQuestions,
      passingGrade: Number(quiz.passingGrade),
    })
      .unwrap()
      .then(({ id }) => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(quiz.name)} ${t(
              'TrainingPlans.toastMessage.created',
            )}`}
          </ToastMessageBlock>
        ));
        // fire amplitude event
        if (track !== null) {
          track('quiz_created', {
            quiz_id: id ?? 'empty',
          });
        }
        if (addToPlan) {
          history.push({
            pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
          });
        } else {
          history.push(
            `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/`,
          );
        }
      })
      .catch(err => {
        if (isApiError(err)) {
          Constants.BUGSNAG_ENABLED &&
            Bugsnag.notify(formatBugsnagErrorMessage(err));
          return <GenericError />;
        }
      });
  };

  const onSaveTranslations = updatedTranslations => {
    setShowManageTranslationsPopup(false);
    updateQuiz(updatedTranslations)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${
              userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
                ? Constants.LANGUAGE_OPTIONS.SPANISH
                : Constants.LANGUAGE_OPTIONS.INGLES
            }`}
          </ToastMessageBlock>
        ));
        refetchGetQuizQuery();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onRenameQuiz = newName => {
    const trimmedName = newName?.trim();
    if (!isValidQuizName(trimmedName, quiz.operatorId)) {
      return;
    }
    const payload = {
      ...quiz,
      name: {
        ...quiz.name,
        [userLanguage]: trimmedName,
      },
    };
    updateQuiz(payload)
      .unwrap()
      .then(() => {
        refetchGetQuizQuery();
        dispatch(setHeader(trimmedName));
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onDeleteQuiz = (id, quizName) => {
    deleteQuiz(id)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>{`${quizName} ${t(
            'TrainingPlans.toastMessage.deleted',
          )}`}</ToastMessageBlock>
        ));
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`,
        });
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const handlePassingGrade = value => {
    setQuiz(prevState => ({
      ...prevState,
      passingGrade: value,
    }));
  };

  const onShowRenameQuizPopup = () => {
    dispatch(hideThreeDotMenu());
    if (isEdited) {
      setShowSaveBeforeRenaming(true);
    } else {
      setShowRenameQuizPopup(true);
    }
  };

  const onDeleteQuizAndClosePopup = () => {
    onDeleteQuiz(quiz.quizId, getNameFromLanguage(quiz.name));
    setShowDeleteQuizPopup(false);
  };

  const onRenameQuizAndClosePopup = inputValue => {
    onRenameQuiz(inputValue);
    setShowRenameQuizPopup(false);
  };

  const onShowDeleteQuizPopup = () => {
    setShowDeleteQuizPopup(true);
    dispatch(hideThreeDotMenu());
  };

  const onShowManageTranslationsPopup = () => {
    dispatch(hideThreeDotMenu());
    if (isEdited) {
      setShowSaveBeforeTranslating(true);
    } else {
      setShowManageTranslationsPopup(true);
    }
  };

  return (
    <QuizViewContainer $isSmAndDown={isSmAndDown}>
      <LoadingOverlay isOpen={isFetchingQuiz} />
      {!!isSmAndDown && (
        <PopoverMenuButton text={t('TrainingPlans.buildQuizzes.manageQuiz')}>
          <PopoverMenuItemButton
            icon={<IconEdit />}
            onClick={onShowRenameQuizPopup}
            testId="ThreeDotMenuQuizPopupRename"
            text={t('TrainingPlans.manageThreeDotMenu.renameQuiz')}
          />
          <PopoverMenuItemButton
            icon={<IconWorld />}
            onClick={onShowManageTranslationsPopup}
            testId="ThreeDotMenuManageTranslationsPopup"
            text={t('Generic.editTranslations')}
          />
          <PopoverMenuItemButton
            icon={<IconTrash />}
            isDestructive
            onClick={onShowDeleteQuizPopup}
            testId="deleteQuizMenuItem"
            text={t('TrainingPlans.manageThreeDotMenu.deleteQuiz')}
          />
        </PopoverMenuButton>
      )}
      <StyledContent>
        {quiz?.questions?.map((question, questionIndex) => (
          <BuildQuizzesQuestionCard
            handlePassingGrade={handlePassingGrade}
            key={`questionCard-${questionIndex}`}
            questionIndex={questionIndex}
            quiz={quiz}
            setQuiz={setQuiz}
            setValidationMode={setValidationMode}
            validationMode={validationMode}
          />
        ))}
        <StyledTextButton
          color="secondary"
          onClick={addQuestion}
          size="lg"
          variant="text"
        >
          <IconPlus />
          {t('Button.addQuestion')}
        </StyledTextButton>
        <Card elevation={1} variant="outlined">
          <CardContent>
            <StyledDropdownWrapper>
              <Dropdown
                data-testid="QuizQuestionDropdown"
                errorText={
                  quiz.passingGrade === '' && validationMode
                    ? t('TrainingPlans.buildQuizzes.errorSelectPassingGrade')
                    : ''
                }
                fullWidth
                label={t(
                  'TrainingPlans.buildQuizzes.correctQuestionsThreshold',
                )}
                onChange={newVal => handlePassingGrade(newVal)}
                options={[
                  '0',
                  ...quiz.questions.map((question, index) =>
                    (index + 1).toString(),
                  ),
                ]}
                placeholder={t('Button.select')}
                required
                value={quiz?.passingGrade}
              />
            </StyledDropdownWrapper>
          </CardContent>
        </Card>
        <StyledButtonsWrapper $isDesktop={isDesktopWidth}>
          <StyledButton
            $isDesktop={isDesktopWidth}
            color="secondary"
            fullWidth={!isDesktopWidth}
            onClick={() => {
              setShowConfirmClosePopUp(true);
            }}
            size="md"
            variant="outlined"
          >
            {t('Button.cancel')}
          </StyledButton>
          <StyledButton
            $isDesktop={isDesktopWidth}
            color="secondary"
            data-testid="SaveQuiz"
            fullWidth={!isDesktopWidth}
            onClick={onSaveQuiz}
            size="md"
            variant="filled"
          >
            {t('Button.saveQuiz')}
          </StyledButton>
        </StyledButtonsWrapper>
        <ConfirmationModal
          bodyText={t('Generic.cancelConfirmation')}
          headerText={t('Generic.unsavedChanges')}
          isOpen={showConfirmClosePopUp}
          onClose={() => setShowConfirmClosePopUp(false)}
          primaryButtonHandler={onConfirmClose}
          primaryButtonText={t('Button.discardChanges')}
          primaryButtonVariant="destructive"
          secondaryButtonHandler={() => setShowConfirmClosePopUp(false)}
          secondaryButtonText={t('Button.continueEditing')}
        />
        <ConfirmationModal
          bodyText={t('TrainingPlans.buildQuizzes.addToPlan')}
          headerText={t('TrainingPlans.buildQuizzes.addToPlanHeader')}
          isOpen={showAddToPlanPopup}
          onClose={() => setShowAddToPlanPopup(false)}
          primaryButtonHandler={() => createQuiz(true)}
          primaryButtonText={t('Button.choosePlan')}
          secondaryButtonHandler={() => createQuiz(false)}
          secondaryButtonText={t('Button.notNow')}
        />
        <ValidationErrorPopup
          setShowValidationError={setShowValidationError}
          showValidationError={showValidationError}
        />
        <DeleteQuizPopup
          onDeleteQuizAndClosePopup={onDeleteQuizAndClosePopup}
          quizName={getNameFromLanguage(quiz.name)}
          setShowDeleteQuizPopup={setShowDeleteQuizPopup}
          showDeleteQuizPopup={showDeleteQuizPopup}
        />
        <RenameQuizPopup
          onRenameQuizAndClosePopup={onRenameQuizAndClosePopup}
          quizDetails={quiz}
          setQuiz={setQuiz}
          setShowRenameQuizPopup={setShowRenameQuizPopup}
          showRenameQuizPopup={showRenameQuizPopup}
        />
        <ManageTranslationsPopup
          isOpen={showManageTranslationsPopup}
          onClose={() => setShowManageTranslationsPopup(false)}
          onSave={onSaveTranslations}
          translatableObject={quiz}
        />

        <ConfirmationModal
          bodyText={t('TrainingPlans.buildQuizzes.saveBeforeRenamingQuiz')}
          headerText={t('Generic.unsavedChanges')}
          isOpen={showSaveBeforeRenaming}
          onClose={() => setShowSaveBeforeRenaming(false)}
          primaryButtonHandler={() => setShowSaveBeforeRenaming(false)}
          primaryButtonText={t('Button.goBack')}
        />
        <ConfirmationModal
          bodyText={t('TrainingPlans.buildQuizzes.saveBeforeTranslatingQuiz')}
          headerText={t('Generic.unsavedChanges')}
          isOpen={showSaveBeforeTranslating}
          onClose={() => setShowSaveBeforeTranslating(false)}
          primaryButtonHandler={() => setShowSaveBeforeTranslating(false)}
          primaryButtonText={t('Button.goBack')}
        />
      </StyledContent>
      <ConfirmationModal
        bodyText={t('InvalidQuizName.paragraphText')}
        headerText={t('InvalidQuizName.errorHeader')}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
      {!isSmAndDown && isEditMode && (
        <StyledStickyMenuCard
          header={t('TrainingPlans.buildQuizzes.manageQuiz')}
          top="24px"
        >
          <StickyMenuButton
            icon={<IconEdit />}
            onClick={onShowRenameQuizPopup}
            testId="ThreeDotMenuQuizPopupRename"
            text={t('TrainingPlans.manageThreeDotMenu.renameQuiz')}
          />
          <StickyMenuButton
            icon={<IconWorld />}
            onClick={onShowManageTranslationsPopup}
            testId="ThreeDotMenuManageTranslationsPopup"
            text={t('Generic.editTranslations')}
          />
          <StickyMenuButton
            error
            icon={<IconTrash />}
            onClick={onShowDeleteQuizPopup}
            testId="deleteQuizMenuItem"
            text={t('TrainingPlans.manageThreeDotMenu.deleteQuiz')}
          />
        </StyledStickyMenuCard>
      )}
    </QuizViewContainer>
  );
};

const QuizViewContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: ${props => (props.$isSmAndDown ? 'column' : 'row')};
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
`;

//needed to override default min-width
const StyledButton = styled(Button)`
  min-width: unset !important;
  margin: ${props => (props.$isDesktop ? '0 0.5em' : '1em 0 0')};
`;

//needed to override default button colors
const StyledTextButton = styled(Button)`
  margin-bottom: 1em;
  color: ${theme.primaryPalette.navyBlue} !important;
`;

const StyledDropdownWrapper = styled.div`
  margin: 0.5em 0 1em 0;

  .cfa-dropdown {
    max-width: 256px;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => (props.$isDesktop ? '2em 0 3em' : '1em 0 3em')};
  flex-direction: ${props => (props.$isDesktop ? 'row' : 'column')};
`;

const StyledStickyMenuCard = styled(StickyMenuCard)`
  margin: unset;
`;

export default withRoles(BuildQuizzesView, [
  Constants.USER_PERMISSIONS.LEADER,
  Constants.USER_PERMISSIONS.OPERATOR,
]);
