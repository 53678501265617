import Constants from 'constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';

const DocumentPreview = ({ id, fileId, versionId }) => {
  const contentUrl = `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/index.html`;
  useDocumentCookieRefresh(id, false);

  return (
    <StyledIFrame
      allow="fullscreen"
      className="content-iframe"
      src={contentUrl}
    />
  );
};

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

DocumentPreview.propTypes = {
  versionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
};

export default DocumentPreview;
