import Constants from 'constants/index';
import { useGetDocumentCookieQuery } from 'services/pathwayApi';

const useDocumentCookieRefresh = (skip = false) => {
  const { isFetching, error } = useGetDocumentCookieQuery(
    {},
    {
      pollingInterval: Constants.DOCUMENT_COOKIE_REFRESH.POLL_TIME_DEFAULT,
      skip: skip,
    },
  );
  // return isFetching and error from hook
  return { isFetching, error };
};

export default useDocumentCookieRefresh;
